import React from "react";
import Footer from "../components/footer/Footer";
import "../components/header/header.css";
import "./flex.css";
import { useState } from "react";
import { useRef } from "react";
import Background from "../components/header/header.jpg";
import { LocalizationConsumer, LocalizationProvider } from "localize-react";
import Flag from "../components/header/flag.svg";
import "../components/content/content.css";
import Options from "../components/content/Options.jsx";
import Buildings from "../components/content/Buildings.jsx";
import Contacts from "../components/content/Contacts";

const TRANSLATIONS = {
  lv: {
    title: "ŪDENSROZES",
    discription: "brīvdienu māja",
    piedavajums: "Piedāvājums",
    komplekss: "Galerija",
    kontakti: "Kontakti",
    rezervacija: "Rezervācija",
  },
  en: {
    title: "ŪDENSROZES",
    discription: "holiday home",
    piedavajums: "Offers",
    komplekss: "Galery",
    kontakti: "Contacts",
    rezervacija: "Reservations",
  },
  ru: {
    title: "УДЕНСРОЗЕС",
    discription: "дом отдыха",
    piedavajums: "Сервис",
    komplekss: "Галерея",
    kontakti: "Контакты",
    rezervacija: "Резервирование",
  },
};

const IndexPage = () => {
  const [lang, setLang] = useState("lv");

  const options = useRef(null);
  const buildings = useRef(null);
  const contacts = useRef(null);

  const optionsScroll = () => options.current.scrollIntoView();
  const buildingsScroll = () => buildings.current.scrollIntoView();
  const ContactsScroll = () => contacts.current.scrollIntoView();

  const styles = {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    display: "block",
  };

  return (
    <div className="App">
      <head>
        <title>Brīvdienu māja "Ūdensrozes"</title>
        <meta
          name="description"
          content="Ūdensrozes ir brīvdienu māja ar plašām atpūtas un izklaides iespējām, klusā vietā, gleznaina ezera krastā."
        />
        <link rel="icon" href={"/favicon.ico"} />
      </head>
      <div style={styles} className="headerimg">
        <div className="langSelector">
          <div className="spacer"></div>

          <button className="langBtn" onClick={() => setLang("lv")}>
            LV
          </button>
          <button className="langBtn" onClick={() => setLang("en")}>
            EN
          </button>
          <button className="langBtn" onClick={() => setLang("ru")}>
            RU
          </button>
        </div>
        <div className="mainflex">
          <div className="spacer"></div>

          <div>
            <LocalizationProvider
              disableCache
              locale={lang}
              translations={TRANSLATIONS}>
              <div className="header">
                <div className="title">
                  <div className="uperTitle">
                    <div className="flagcontainer">
                      <img className="flag" alt="" src={Flag}></img>
                    </div>
                    <div className="titleContainer">
                      <h2 className="discription">
                        <LocalizationConsumer>
                          {({ translate }) => translate("discription")}
                        </LocalizationConsumer>
                      </h2>
                      <h1 className="udensrozes">
                        <LocalizationConsumer>
                          {({ translate }) => translate("title")}
                        </LocalizationConsumer>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="topNav">
                <div className="linkcontainer" onClick={optionsScroll}>
                  <div className="link">
                    <LocalizationConsumer>
                      {({ translate }) => translate("piedavajums")}
                    </LocalizationConsumer>
                  </div>
                </div>

                <div className="linkcontainer" onClick={buildingsScroll}>
                  <div className="link">
                    <LocalizationConsumer>
                      {({ translate }) => translate("komplekss")}
                    </LocalizationConsumer>
                  </div>
                </div>

                <div className="linkcontainer" onClick={ContactsScroll}>
                  <div className="link">
                    <LocalizationConsumer>
                      {({ translate }) => translate("kontakti")}
                    </LocalizationConsumer>
                  </div>
                </div>
                {/*
                <div className="linkcontainer">
                  <div className="link">
                    <LocalizationConsumer>
                      {({ translate }) => translate("rezervacija")}
                    </LocalizationConsumer>
                  </div>
                </div>
                */}
              </div>
            </LocalizationProvider>
          </div>

          <div className="content">
            <div ref={options}>
              <Options language={lang} />
            </div>
            <div ref={buildings}>
              <Buildings />
            </div>
            <div ref={contacts}>
              <Contacts language={lang} />
            </div>
            {/*<Reservation />*/}
            <Footer language={lang} />
          </div>

          {/*<div ref={myRef}>Element to scroll to</div>*/}
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
