import "./options.css";
import "./optionsexp.css";

import { LocalizationConsumer, LocalizationProvider } from "localize-react";
import Price from "./Price";

import { StaticImage } from "gatsby-plugin-image";

import React from "react";
const TRANSLATIONS = {
  lv: {
    divvietigs: "Divvietīgs numurs",
    trisvietigs: "Trīsvietīgs numurs",
    cetrvietigs: "Četrvietīgs numurs",
    luksusa: "Luksusa numurs",
    jaunlaulatie: "Jaunlaulāto numurs",
    telsuvietas: "Telšu vietas",
    trisdesmitpersonam: "Svinību zāle 30 personām",
    sesdesmitpersonam: "Svinību zāle 60 personām",
    terase: "Āra terase",
    stiklotaterase: "Stiklotā terase",
    diennakti: "diennaktī",
    laivunoma: "Laivu noma",
    makskeresnoma: "Makšķeres noma",
    makskeresana: "Makšķerēšana mākslīgā ūdenstilpnē",
    sauna: "Sauna",
    estrade: "Estrāde",
    kubls: "Kubls",
    viesumaja: "Viesu māja \"Saulesstars\"",
    grils: "Grilvieta zem nojumes",
    pirts: "Pirts"
  },
  ru: {
    divvietigs: "Двойной номер",
    trisvietigs: "Трехместный номер",
    cetrvietigs: "Четырехместный номер",
    luksusa: "Делюкс номер",
    jaunlaulatie: "Номер для молодоженов",
    telsuvietas: "Палаточные площадки",
    trisdesmitpersonam: "банкетный зал на 30 человек",
    sesdesmitpersonam: "банкетный зал на 60 человек",
    terase: "Открытая терраса",
    stiklotaterase: "Застекленная терраса",
    laivunoma: "Аренда лодок",
    makskeresnoma: "Прокат удочек",
    makskeresana: "Рыбалка в пруду",
    sauna: "Сауна",
    estrade: "Сцена",
    kubls: "Bанна",
    viesumaja: "Гостевой дом \"Saulesstars\"",
    grils: "Гриль-зона под навесом",
    pirts: "Мобильная сауна"


  },
  en: {
    divvietigs: "Room for 2 persons",
    trisvietigs: "Room for 3 persons",
    cetrvietigs: "Room for 4 persons",
    luksusa: "Superior room",
    jaunlaulatie: "Superior room",
    telsuvietas: "Tent sites",
    trisdesmitpersonam: "30 person banquet hall",
    sesdesmitpersonam: "60 person banquet hall",
    terase: "External terrace",
    stiklotaterase: "Indoor glass terrace",
    laivunoma: "Boat rental",
    makskeresana: "Fishing in an artificial pond",
    makskeresnoma: "Fishing rod rental",
    sauna: "Sauna",
    estrade: "Stage",
    kubls: "Tub",
    viesumaja: "Guest house \"Saulesstars\"",
    grils: "Grill area under the terrace",
    pirts: "Mobile sauna"

  },
};
let trisvietigie = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/trisvietu/t1.jpg",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/trisvietu/t2.jpg",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/trisvietu/t3.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/trisvietu/t3.jpg",
  },
];
let cetrvietigie = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/cetrvietu/c1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/cetrvietu/c1.jpg",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/cetrvietu/c2.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/cetrvietu/c2.jpg",
    originalClass: "",
  },
];
let lukss = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/lukss/l1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/lukss/l1.jpg",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/lukss/l1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/lukss/l1.jpg",
  },
];
let jaunlaulatie = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/jaunlaulatie/j1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/jaunlaulatie/j1.jpg",
  },
];
let telsuvietas = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/telsuvietas/t1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/telsuvietas/t1.jpg",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/telsuvietas/t2.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/telsuvietas/t2.jpg",
    originalClass: "",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/telsuvietas/t3.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/telsuvietas/t3.jpg",
    originalClass: "",
  },
];
let trisdesmitzalezale = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/30vietiga/trisviet1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/30vietiga/trisviet1.jpg",
    originalClass: "",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/30vietiga/trisviet2.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/30vietiga/trisviet2.jpg",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/30vietiga/trisviet3.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/30vietiga/trisviet3.jpg",
    originalClass: "",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/30vietiga/trisviet4.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/30vietiga/trisviet4.jpg",
  },
];
let sesdesmitvietiga = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/60vietiga/sesdviet1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/60vietiga/sesdviet1.jpg",
    originalClass: "",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/60vietiga/sesdviet2.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/60vietiga/sesdviet2.jpg",
  },
];
let terase = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/terase/te1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/terase/te1.jpg",
    originalClass: "",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/terase/te2.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/terase/te2.jpg",
    originalClass: "",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/terase/te3.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/terase/te3.jpg",
    originalClass: "",
  },
];
let laivunoma = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/laivunoma/ln1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/laivunoma/ln1.jpg",
    originalClass: "",
  },
];
let makskeresnoma = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/makskeresnoma/mn1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/makskeresnoma/mn1.jpg",
    originalClass: "",
  },
];
let makskeresana = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/makskeresana/mk1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/makskeresana/mk1.jpg",
    originalClass: "",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/makskeresana/mk2.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/makskeresana/mk2.jpg",
    originalClass: "",
  },
];
let sauna = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/sauna/s2.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/sauna/s2.jpg",
    originalClass: "",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/sauna/s3.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/sauna/s3.jpg",
    originalClass: "",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/sauna/s4.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/sauna/s4.jpg",
    originalClass: "",
  },
];
let Images = [
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/divvietu/d1.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/divvietu/d1.jpg",
    originalClass: "",
  },
  {
    original:
      "https://admiring-cori-62a576.netlify.app/nummbildes/divvietu/d2.jpg",
    thumbnail:
      "https://admiring-cori-62a576.netlify.app/nummbildes/divvietu/d2.jpg",
  },
];
export default function Options({ language }) {
  return (
    <div className="options" id="offers">
      <div>
        <LocalizationProvider
          disableCache
          locale={language}
          translations={TRANSLATIONS}>
          <div className="roomsContainer">
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("divvietigs")}
                </LocalizationConsumer>
              </h1>
              <img
                src={"/nummbildes/divvietu.webp"}
                alt=""
                className="galery"
              />
              <div className="price">
                <Price language={language} Price="30" Discription="diennakti" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("trisvietigs")}
                </LocalizationConsumer>
              </h1>
              <img
                src={"/nummbildes/trisvietu.webp"}
                alt=""
                className="galery"
              />
              <div className="price">
                <Price language={language} Price="30" Discription="diennakti" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("cetrvietigs")}
                </LocalizationConsumer>
              </h1>
              <img
                src={"/nummbildes/cetrvietu.webp"}
                alt=""
                className="galery"
              />
              <div className="price">
                <Price language={language} Price="40" Discription="diennakti" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("luksusa")}
                </LocalizationConsumer>
              </h1>
              <img src={"/nummbildes/lukss.webp"} alt="" className="galery" />
              <div className="price">
                <Price language={language} Price="50" Discription="diennakti" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("jaunlaulatie")}
                </LocalizationConsumer>
              </h1>
              <img
                src={"/nummbildes/jaunlaulatie.webp"}
                alt=""
                className="galery"
              />
              <div className="price">
                <Price language={language} Price="60" Discription="diennakti" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("telsuvietas")}
                </LocalizationConsumer>
              </h1>
              <img
                src={"/nummbildes/telsuvietas.webp"}
                alt=""
                className="galery"
              />
              <div className="price">
                <Price language={language} Price="10" Discription="diennakti" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("trisdesmitpersonam")}
                </LocalizationConsumer>
              </h1>
              <img
                src={"/nummbildes/trisdesmitviet.webp"}
                alt=""
                className="galery"
              />
              <div className="price">
                <Price language={language} Price="200-230" Discription="atkarigsnodienas" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("sesdesmitpersonam")}
                </LocalizationConsumer>
              </h1>
              <img
                src={"/nummbildes/60vietiga.webp"}
                alt=""
                className="galery"
              />
              <div className="price">
                <Price language={language} Price="300-350" Discription="atkarigsnodienas" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("terase")}
                </LocalizationConsumer>
              </h1>
              <img src={"/nummbildes/terase.webp"} alt="" className="galery" />
              <div className="price">
                <Price language={language} Price="130-150" Discription="atkarigsnodienas" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("stiklotaterase")}
                </LocalizationConsumer>
              </h1>
              <img src={"/nummbildes/terase-1.webp"} alt="" className="galery" />
              <div className="price">
                <Price language={language} Price="130-150" Discription="atkarigsnodienas" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("laivunoma")}
                </LocalizationConsumer>
              </h1>
              <img
                src={"/nummbildes/laivunoma.webp"}
                alt=""
                className="galery"
              />
              <div className="price">
                <Price language={language} Price="10" Discription="diena" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("makskeresnoma")}
                </LocalizationConsumer>
              </h1>
              <img
                src={"/nummbildes/makskeresnoma.webp"}
                alt=""
                className="galery"
              />
              <div className="price">
                <Price language={language} Price="5" Discription="diena" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("makskeresana")}
                </LocalizationConsumer>
              </h1>
              <img
                src={"/nummbildes/makskeresana.webp"}
                alt=""
                className="galery"
              />
              <div className="price">
                <Price language={language} Price="4" Discription="kg" />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("sauna")}
                </LocalizationConsumer>
              </h1>
              <img src={"/nummbildes/sauna.webp"} alt="" className="galery" />
              <div className="price">
                <Price
                  language={language}
                  Price="30"
                  Discription="stunda"
                />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("estrade")}
                </LocalizationConsumer>
              </h1>
              <img src={"/nummbildes/estrade.jpg"} alt="" className="galery" />
              <div className="price">
                <Price
                    language={language}
                    Price="70"
                    Discription="pasakums"
                />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("kubls")}
                </LocalizationConsumer>
              </h1>
              <img src={"/nummbildes/kubls.jpg"} alt="" className="galery" />
              <div className="price">
                <Price
                    language={language}
                    Price="60"
                    Discription="vakars"
                />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("viesumaja")}
                </LocalizationConsumer>
              </h1>
              <img src={"/nummbildes/saulesstars.jpg"} alt="" className="galery" />
              <div className="price">
                <Price
                    language={language}
                    Price="130-150"
                    Discription="atkarigsnodienas"
                />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("grils")}
                </LocalizationConsumer>
              </h1>
              <img src={"/nummbildes/grils.jpg"} alt="" className="galery" />
              <div className="price">
                <Price
                    language={language}
                    Price="20"
                    Discription="diena"
                />
              </div>
            </div>
            <div className="roomsCard">
              <h1 className="cardtitle">
                <LocalizationConsumer>
                  {({ translate }) => translate("pirts")}
                </LocalizationConsumer>
              </h1>
              <img src={"/nummbildes/pirts.jpg"} alt="" className="galery" />
              <div className="price">
                <Price
                    language={language}
                    Price="60"
                    Discription="vakars"
                />
              </div>
            </div>
          </div>
        </LocalizationProvider>
      </div>
    </div>
  );
}
