import React from "react";
import ImageGallery from "react-image-gallery";
import "./buildings.css";

const Images = [
  {
    original: "/kompleksabildes/a.jpg",
    thumbnail: "/kompleksabildes/a.jpg",
  },
  {
    original: "/kompleksabildes/a1.jpg",
    thumbnail: "/kompleksabildes/a1.jpg",
  },
  {
    original: "/kompleksabildes/a3.jpg",
    thumbnail: "/kompleksabildes/a3.jpg",
  },
  {
    original: "/kompleksabildes/a4.jpg",
    thumbnail: "/kompleksabildes/a4.jpg",
  },
  {
    original: "/kompleksabildes/a5.jpg",
    thumbnail: "/kompleksabildes/a5.jpg",
  },
  {
    original: "/kompleksabildes/a7.jpg",
    thumbnail: "/kompleksabildes/a7.jpg",
  },
  {
    original: "/kompleksabildes/a8.jpg",
    thumbnail: "/kompleksabildes/a8.jpg",
  },
  {
    original: "/kompleksabildes/a9.jpg",
    thumbnail: "/kompleksabildes/a9.jpg",
  },
  {
    original: "/kompleksabildes/a10.jpg",
    thumbnail: "/kompleksabildes/a10.jpg",
  },
  {
    original: "/kompleksabildes/a11.jpg",
    thumbnail: "/kompleksabildes/a11.jpg",
  },
  {
    original: "/kompleksabildes/a12.jpg",
    thumbnail: "/kompleksabildes/a12.jpg",
  },
  {
    original: "/kompleksabildes/a13.jpg",
    thumbnail: "/kompleksabildes/a13.jpg",
  },
  {
    original: "/kompleksabildes/a14.jpg",
    thumbnail: "/kompleksabildes/a14.jpg",
  },
  {
    original: "/kompleksabildes/a15.jpg",
    thumbnail: "/kompleksabildes/a15.jpg",
  },
  {
    original: "/kompleksabildes/a16.jpg",
    thumbnail: "/kompleksabildes/a16.jpg",
  },
  {
    original: "/kompleksabildes/a17.jpg",
    thumbnail: "/kompleksabildes/a17.jpg",
  },
  {
    original: "/kompleksabildes/a18.jpg",
    thumbnail: "/kompleksabildes/a18.jpg",
  },
  {
    original: "/kompleksabildes/a19.jpg",
    thumbnail: "/kompleksabildes/a19.jpg",
  },
  {
    original: "/kompleksabildes/a20.jpg",
    thumbnail: "/kompleksabildes/a20.jpg",
  },
  {
    original: "/kompleksabildes/a21.jpg",
    thumbnail: "/kompleksabildes/a21.jpg",
  },
];

export default function Buildings() {
  return (
    <div className="buildings" id="buildings">
      <div className="buildingsgalery">
        <ImageGallery items={Images}></ImageGallery>
      </div>
    </div>
  );
}
