import Mail from "./icons/mail.png";
import Phone from "./icons/phone.png";
import PinPoint from "./icons/pinpoint.png";
import axios from "axios";
import { useState } from "react";
import { LocalizationConsumer, LocalizationProvider } from "localize-react";
import React from "react";
const TRANSLATIONS = {
  lv: {
    contactUs: "Sazinies ar mums",
    email: "Jūsu e-pasts",
    msg: "Ziņa",
    send: "Nosūtīt",
  },
  ru: {
    contactUs: "Свяжитесь с нами",
    email: "Ваша злектронная почта",
    msg: "Сообщение",
    send: "Отправить",
  },
  en: {
    contactUs: "Contact Us",
    email: "Your email",
    msg: "Message",
    send: "Send",
  },
};

export default function Contacts({ language }: { language: any }) {
  const [email, setEmail] = useState("");
  const [mesage, setMesage] = useState("");

  function Send() {
    setEmail("");
    setMesage("");
    const sendableMessage =
      "Saņemta jauna ziņa no mājaslapas\n" +
      "<b>E-pasts: </b>" +
      email +
      "\n<b>Ziņa: </b>" +
      mesage;
    console.log(sendableMessage);

    axios
      .post(
        "https://api.telegram.org/bot1872534401:AAEgLCPFMCfZ6-0LZOAj7GKhhXPlSxSgL7c/sendMessage",
        {
          chat_id: "908824264",
          text: sendableMessage,
          parse_mode: "HTML",
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div className="contacts" id="contacts">
      <LocalizationProvider
        disableCache
        locale={language}
        translations={TRANSLATIONS}>
        <div className="contactside">
          <div className="contactsection">
            <img src={Mail} alt="email" className="icon"></img>
            <div className="contacttext">drusti.udensrozes@inbox.lv</div>
          </div>
          <div className="contactsection">
            <img src={Phone} alt="phone" className="icon"></img>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.3em",
              }}>
              <div className="contacttext">+371 29626107</div>
              <div className="contacttext">+371 28875758</div>
            </span>
          </div>
          <div className="contactsection">
            <img src={PinPoint} alt="location" className="icon"></img>
            <div className="contacttext">
              "Ūdensrozes" Drustu pagasts, Smiltenes novads
            </div>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2160.864128266609!2d25.8698645!3d57.2077425!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6e3b271d2b696e5!2zQnLEq3ZkaWVudSBtxIFqYSAixapkZW5zcm96ZXMi!5e0!3m2!1slv!2slv!4v1635265090686!5m2!1slv!2slv"
          width="600"
          height="450"
          style={{ border: "none" }}
          className="map"
          loading="lazy"></iframe>
        <div className="contactform">
          <h1 className="contactformtitle">
            <LocalizationConsumer>
              {({ translate }) => translate("contactUs")}
            </LocalizationConsumer>
          </h1>
          <div className="contactinput">
            <h2>
              <LocalizationConsumer>
                {({ translate }) => translate("email")}
              </LocalizationConsumer>
            </h2>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}></input>
          </div>
          <div className="contactinput">
            <h2>
              <LocalizationConsumer>
                {({ translate }) => translate("msg")}
              </LocalizationConsumer>
            </h2>
            <textarea
              value={mesage}
              onChange={(e) => setMesage(e.target.value)}></textarea>
          </div>
          <button className="contactBtn" onClick={Send}>
            <LocalizationConsumer>
              {({ translate }) => translate("send")}
            </LocalizationConsumer>
          </button>
        </div>
      </LocalizationProvider>
    </div>
  );
}
