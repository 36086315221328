import "./price.css";
import React from "react";
import { LocalizationConsumer, LocalizationProvider } from "localize-react";

const TRANSLATIONS = {
  lv: {
    diennakti: "diennaktī",
    pasakums: "pasākums",
    stunda: "stundā",
    diena: "dienā",
    kg: "kilogramā",
    vienaipersonaistunda: "vienai personai stundā",
    vakars: "vakarā",
    atkarigsnodienas: "atkarīgs no dienas"
  },
  ru: {
    pasakums: "мероприятие",
    stunda: "час",
    diennakti: "сутки",
    diena: "день",
    kg: "за килограмм",
    vienaipersonaistunda: "на человека в час",
    vakars: "bечер",
    atkarigsnodienas: "зависит от дня"


  },
  en: {
    pasakums: "per event",
    stunda: "per hour",
    diennakti: "per day",
    diena: "per day",
    kg: "per kg",
    vienaipersonaistunda: "per hour for one person",
    vakars: "per night",
    atkarigsnodienas: "depending on the day of the week"

  },
};

export default function Price({ Price, Discription, language }) {
  return (
    <LocalizationProvider
      disableCache
      locale={language}
      translations={TRANSLATIONS}>
      <div className="price">
        <h3>{Price}</h3>
        <span>&#8364;</span>
        <div className="horline"></div>
        <h4>
          <LocalizationConsumer>
            {({ translate }) => translate(Discription)}
          </LocalizationConsumer>
        </h4>
      </div>
    </LocalizationProvider>
  );
}
