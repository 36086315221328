import "./footer.css";
import Flag from "../header/flag.svg";
import React from "react";
import { LocalizationConsumer, LocalizationProvider } from "localize-react";

const TRANSLATIONS = {
  lv: {
    pvn: "Visas cenas norādītas bez PVN",
  },
  en: {
    pvn: "All prices without taxes",
  },
  ru: {
    pvn: "Бсе цены без налогов",
  },
};

export default function Footer({ language }: any) {
  return (
    <div className="footer">
      <LocalizationProvider
        disableCache
        locale={language}
        translations={TRANSLATIONS}>
        <div className="footercontainer">
          <img src={Flag} alt="flag" className="footerFlag"></img>
          <div style={{ height: "40px" }}></div>
          <p className="copyright">
          </p>
        </div>

        {/*<div className="contactside">
        <div className="footersection">
          <img src={Phone} className="icon"></img>
          <p>+371 29188820</p>
        </div>
        <div className="footersection">
          <img src={PinPoint} className="icon"></img>
          <p>Krišijāņa Voldemāra iela 2, Drustu pagasts, Cēsu novads, Latvija</p>
        </div>
        <div className="footersection">
          <img src={Mail} className="icon"></img>
          <p>marcis-andersons@protonmail.com</p>
        </div>
      </div> */}
      </LocalizationProvider>
    </div>
  );
}
